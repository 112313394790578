import { ApiClient } from './api'
import { isAppMode } from '../base/appFlags'
let client = new ApiClient('/api/v1/')
const currentParams = new URLSearchParams(window.location.search)

const handlePassedUTMParams = (response, isArray) => {
  if (isArray) {
    response.data = response.data.map(item => {
      if (item.locked_url) {
        item.locked_url = mergeLockedUrlParamsWithCurrentUrlParams(item.locked_url)
      }
      return item
    })
  } else {
    if (response.data.locked_url) {
      response.data.locked_url = mergeLockedUrlParamsWithCurrentUrlParams(response.data.locked_url)
    }
  }
  return response
 }

const mergeLockedUrlParamsWithCurrentUrlParams = (lockedUrl) => {
  const params = new URL(lockedUrl).searchParams
  currentParams.forEach((value, key) => {
    params.set(key, value)
  })
  lockedUrl = `${lockedUrl.split('?')[0]}?${params.toString()}`
  return lockedUrl
}


export default {
  getOnboardingUser (uid) {
    return client.get(`onboarding/${uid}`)
  },
  loginUser(data) {
    return client.post('login-with-email/send', data)
  },
  registerUser (data) {
    return client.post('register', data)
  },
  forgotPassword (data) {
    return client.post('forgot-password', data)
  },
  resetPassword (data) {
    return client.post('reset-password', data)
  },
  getUser () {
    return client.get('user')
  },
  updateUser (data) {
    return client.post('user', data, {
      noInterceptors: true
    })
  },
  getBanner () {
    return client.get('banner')
  },
  dismissBanner (id) {
    return client.patch(`banner/${id}`, {
      is_dismissed: true
    })
  },
  getActivity(page) {
    let config = {
      params: {
        page: page
      }
    };
    if (isAppMode()) {
        config.headers = {'X-Requested-With': 'mobile'};
    }
    return client.get(`activity`, config);
  },
  getPopup() {
    let lessonId = window.location.pathname.split('/').pop() || null;
    if (lessonId && Number.isNaN(parseInt(lessonId))) lessonId = null;

    return client.get(`popup?lessonId=${lessonId}`);
  },
  getSettings () {
    return client.get(`settings`)
  },
  updateSettings (settings) {
    return client.post(`settings`, settings)
  },
  getAchievements () {
    return client.get(`achievements`)
  },
  getNotifications() {
    const options = {};

    if (isAppMode()) {
      options.headers = {
        'X-Requested-With': 'mobile'
      };
      options.params = {
        keep_unread: true
      };
    }

    return client.get('notifications', options);
  },
  readNotification (id, read) {
    let data = {
      is_read: read
    };
    let config = {};
    if (isAppMode()) {
        config.headers = {'X-Requested-With': 'mobile'};
    }
    return client.patch(`notifications/${id}`, data, config);
  },
  readAllNotifications () {
    let data = {
      mark_all_as_read: true
    };
    let config = {
      noInterceptors: true
    }
    if (isAppMode()) {
        config.headers = {'X-Requested-With': 'mobile'};
    }
    // Assuming you're using some kind of HTTP client like Axios
    return client.post(`notifications`, data, config)
  },
  getCourses () {
    return client.get(`courses`).then(resp => handlePassedUTMParams(resp, true))
  },
  getCourse (id) {
    return client.get(`courses/${id}?include=modules.lessons&without-notes`).then(resp => handlePassedUTMParams(resp))
  },
  getSharedCourse (id) {
    return client.get(`shared-courses/${id}?include=modules.lessons`).then(resp => handlePassedUTMParams(resp))
  },
  getLesson (id) {
    return client.get(`lessons/${id}?include=notes`).then(resp => handlePassedUTMParams(resp))
  },
  getSharedLesson (id) {
    return client.get(`shared-lessons/${id}`).then(resp => handlePassedUTMParams(resp))
  },
  getComments (id, page, commentId) {
    return client.get(`lessons/${id}/comments`, {
      // Flag to disable global interceptors
      noInterceptors: true,
      params: {
        page: page,
        // ES6 conditional object property
        ...(commentId && { comment_id: commentId})
      }
    })
  },
  getSharedComments (id, page) {
    return client.get(`shared-lessons/${id}/comments`, {
      // Flag to disable global interceptors
      noInterceptors: true,
      params: {
        page: page
      }
    })
  },
  addComment (id, content, replyId) {
    return client.post(`lessons/${id}/comments`, {
      content: content,
      // ES6 conditional object property
      ...(replyId && { reply_to: replyId })
    })
  },
  deleteComment (id) {
    return client.delete(`comments/${id}`)
  },
  rateReply (id, rate) {
    return client.patch(`comments/${id}`, {
      rating: rate
    }, {
      noInterceptors: true
    })
  },
  setProgress (id, seconds) {
    return client.post(`lessons/${id}/progress`, {
      seconds: seconds
    }, {
      noInterceptors: true
    })
  },
  getPublicProfile (id) {
    return client.get(`user/${id}`)
  },
  getReferLink () {
    return client.get(`refer-a-friend`)
  },
  getPaypalAccount () {
    return client.get('paypal-account')
  },
  setPaypalAccount (email) {
    return client.post('paypal-account', {
      email: email
    }, {
      noInterceptors: true
    })
  },
  getReferrals (page = 1) {
    return client.get(`friend-referrals?page=${page}`)
  },
  getQuickTips(params) {
    return client.get(`quick-tips?${params.includes}`, {
      params: params.filters
    })
  },
  setQuickTipsVideoLike(id, isLike) {
    if (isLike) return client.post(`quick-tips/${id}/like`)
    return client.delete(`quick-tips/${id}/like`)
  },
  setQuickTipsVideoProgress(id, seconds) {
    return client.post(`quick-tips/${id}/progress`, {
      seconds
    })
  },
  getQuickTipsComments(id) {
    return client.get(`quick-tips/${id}/comments`)
  },
  addQuickTipsComment (id, content, replyId) {
    return client.post(`quick-tips/${id}/comments`, {
      content: content,
      // ES6 conditional object property
      ...(replyId && { reply_to: replyId })
    })
  },
  getQuickTipsCategories(options) {
    const params = new URLSearchParams(options)
    return client.get(`quick-tips/categories?${params.toString()}`)
  },
  createAiImageReview(filename) {
    return client.post('ai-image-reviews', {
      file_name: filename
    })
  },
  uploadImageReview(url, file) {
    return client.put(url, file, {
      headers: {
        'Content-Type': 'image/jpeg'
      }
    })
  },
  generateReview(id, isSamplePhoto) {
    return client.put(`ai-image-reviews/${id}`, {
      is_sample_photo: isSamplePhoto
    })
  },
  getPhotoReviews(page) {
    return client.get('ai-image-reviews',{
      noInterceptors: true,
      params: {
        page: page,
        per_page: 9
      }
    })
  },
  getPhotoReview(id) {
    return client.get(`ai-image-reviews/${id}`)
  },
  deleteUser() {
    return client.post('user/delete')
  },
  addDeviceToken(token) {
    return client.post('cio/devices', {
      device_id: token
    })
  },
  removeDeviceToken(token) {
    return client.delete(`cio/devices/${token}`)
  }
}
